import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cards from "../components/cards"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    {/* <!-- Projects Section --> */}
    <section className="section-projects">
      <div className="u-center-text u-margin-bottom-big" id="section-projects">
        <h2 className="heading-secondary u-margin-bottom-medium">
          Here Are Some Things I have made:
        </h2>
      </div>
      <Cards />
      <a
        href="#contact-form"
        className="btn btn--white u-margin-top-large"
        aria-label="button 9"
        tabindex="0"
      >
        {" "}
        Let me make stuff for you!{" "}
      </a>
    </section>
    {/* <!-- End Projects Section --> */}
    {/* <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
  </Layout>
)

export default IndexPage
