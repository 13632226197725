import React from "react"
import BackgroundImage from "gatsby-background-image"

const MultiBackground = ({ className }) => {
  const { hero, img1, img2, img3 } = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "Library.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        img1: file(relativePath: { eq: "Rock-Paper-Scissors.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 420) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        img2: file(relativePath: { eq: "Etch-a-Sketch.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 420) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        img3: file(relativePath: { eq: "MyWindows10Calculator.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 420) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Watch out for CSS's stacking order, especially when styling the individual
  // positions! The lowermost image comes last!
  const backgroundFluidImageStack = [
    hero.childImageSharp.fluid,
    img1.childImageSharp.fluid,
    img2.childImageSharp.fluid,
    img3.childImageSharp.fluid,
  ].reverse()

  return (
    <BackgroundImage
      Tag={`section`}
      className={card__picture}
      fluid={backgroundFluidImageStack}
    >
      <h2>This is a test of multiple background images.</h2>
    </BackgroundImage>
  )
}

const Cards = () => (
  <div>
    <div className="row">
      <div className="col-1 collapsible u-margin-bottom-large">
        <div className="card" tabindex="0">
          <div className="card__side card__side--front">
            <div className="card__picture card__picture-hero">&nbsp;</div>
            <h2 className="card__heading">
              <span className="card__heading-span card__heading-span--1">
                Library
              </span>
            </h2>
            <div className="card__details">
              <ul>
                <li>TypeScript</li>
                <li>Semantic UI</li>
                <li>Local Storage</li>
                <li>MVC</li>
                <li>CSS</li>
              </ul>
            </div>
          </div>
          <div className="card__side card__side--back card__side--back-1">
            <div className="card__cta">
              <a
                href="https://github.com/Mac-Mann/Library"
                target="_blank"
                rel="noreferrer"
                className="btn btn--white"
                aria-label="button 1"
                tabindex="0"
              >
                <i className="fab fa-github"></i>
              </a>
              <br />
              <a
                href="https://mac-mann.github.io/Library/"
                target="_blank"
                rel="noreferrer"
                className="btn btn--white"
                aria-label="button 2"
                tabindex="0"
              >
                <i className="fab fa-chrome"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-1-of-3 collapsible">
        <div className="card" tabindex="0">
          <div className="card__side card__side--front">
            <div className="card__picture card__picture-1">&nbsp;</div>
            <h2 className="card__heading">
              <span className="card__heading-span card__heading-span--1">
                Rock-Paper-Scissors
              </span>
            </h2>
            <div className="card__details">
              <ul>
                <li>TypeScript</li>
                <li>SASS</li>
                <li>Parcel</li>
              </ul>
            </div>
          </div>
          <div className="card__side card__side--back card__side--back-1">
            <div className="card__cta">
              <a
                href="https://github.com/Mac-Mann/Rock-Paper-Scissors"
                target="_blank"
                rel="noreferrer"
                className="btn btn--white"
                aria-label="button 3"
                tabindex="0"
              >
                <i className="fab fa-github"></i>
              </a>
              <br />
              <a
                href="https://Mac-Mann.github.io/Rock-Paper-Scissors/"
                target="_blank"
                rel="noreferrer"
                className="btn btn--white"
                aria-label="button 4"
                tabindex="0"
              >
                <i className="fab fa-chrome"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-1-of-3 collapsible">
        <div className="card" tabindex="0">
          <div className="card__side card__side--front">
            <div className="card__picture card__picture-2">&nbsp;</div>
            <h2 className="card__heading">
              <span className="card__heading-span card__heading-span--2">
                Etch-a-Sketch
              </span>
            </h2>
            <div className="card__details">
              <ul>
                <li>JQuery</li>
                <li>TypeScript</li>
                <li>SASS</li>
                <li>Parcel</li>
              </ul>
            </div>
          </div>
          <div className="card__side card__side--back card__side--back-2">
            <div className="card__cta">
              <a
                href="https://github.com/Mac-Mann/Etch-a-Sketch"
                target="_blank"
                rel="noreferrer"
                className="btn btn--white"
                aria-label="button 5"
                tabindex="0"
              >
                <i className="fab fa-github"></i>
              </a>
              <br />
              <a
                href="https://Mac-Mann.github.io/Etch-a-Sketch/"
                target="_blank"
                rel="noreferrer"
                className="btn btn--white"
                aria-label="button 6"
                tabindex="0"
              >
                <i className="fab fa-chrome"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-1-of-3 collapsible">
        <div className="card" tabindex="0">
          <div className="card__side card__side--front">
            <div className="card__picture card__picture-3">&nbsp;</div>
            <h2 className="card__heading">
              <span className="card__heading-span card__heading-span--3">
                Windows 10 Calculator
              </span>
            </h2>
            <div className="card__details">
              <ul>
                <li>Vanilla JS</li>
                <li>CSS</li>
                <li>HTML</li>
              </ul>
            </div>
          </div>
          <div className="card__side card__side--back card__side--back-3">
            <div className="card__cta">
              <a
                href="https://github.com/Mac-Mann/Windows10-Calculator"
                target="_blank"
                rel="noreferrer"
                className="btn btn--white"
                aria-label="button 7"
                tabindex="0"
              >
                <i className="fab fa-github"></i>
              </a>
              <br />
              <a
                href="https://Mac-Mann.github.io/Windows10-Calculator/"
                target="_blank"
                rel="noreferrer"
                className="btn btn--white"
                aria-label="button 8"
                tabindex="0"
              >
                <i className="fab fa-chrome"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Cards
